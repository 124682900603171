import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import icon1 from '../../assets/images/icons/icon1.png'
import icon2 from '../../assets/images/icons/icon2.png'
import icon3 from '../../assets/images/icons/icon3.png'
import shape3 from '../../assets/images/services/service-shape3.png'

import shape2 from '../../assets/images/shape/circle-shape2.png'

const OurServices = () => {
    return (
        <section className="services-area pt-70 pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" />
                        Small, medium, large
                    </span>

                    <h2>How can we help you</h2>
                    <p>Whether you are a budding start-up or a large corporation, our skilled software experts are here to help. From providing individual services or full project lifecycle support project managers to software engineers, our in-house team takes care of your project from scoping through to final delivery, working to your budget and timeline.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon1} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                    Start-Ups
                                </Link>
                            </h3>
                            <ul className="services-list">
                                <li><span>Funded startups</span><i className="flaticon-tick"></i></li>
                                <li><span>New lines of business</span><i className="flaticon-tick"></i></li>
                                <li><span>Design & develop new applications</span><i className="flaticon-tick"></i></li>
                                <li><span>Full lifecycle support</span><i className="flaticon-tick"></i></li>
                                <li><span>Speed, flexibility & confidence</span><i className="flaticon-tick"></i> </li>
                            </ul>
                            <Link to="/contact" className="read-more-btn">
                                <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={shape3} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                    Enterprises
                                </Link>
                            </h3>
                            <ul className="services-list">
                                <li><span>Mid-sized to large companies</span><i className="flaticon-tick"></i></li>
                                <li><span>Improve existing enterprise software</span><i className="flaticon-tick"></i></li>
                                <li><span>Design & develop new applications</span><i className="flaticon-tick"></i></li>
                                <li><span>Rebranding and transformation</span><i className="flaticon-tick"></i></li>
                                <li><span>Educate & train internal teams</span><i className="flaticon-tick"></i></li>
                            </ul>
                            <Link to="/contact" className="read-more-btn">
                                <i className="flaticon-right"></i>
                                Learn More

                            </Link>
                            <div className="shape">
                                <img src={shape3} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon3} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                    Individual services
                                </Link>
                            </h3>
                            <ul className="services-list">
                                <li><i className="flaticon-tick"></i> <span> Analysis, design and specification</span></li>
                                <li><i className="flaticon-tick"></i> <span>Bespoke development</span></li>
                                <li><i className="flaticon-tick"></i> <span>Budget management</span></li>
                                <li><i className="flaticon-tick"></i> <span>Business consultancy</span></li>
                                <li><i className="flaticon-tick"></i> <span>Interface analysis and design</span></li>
                            </ul>
                            <Link to="/contact" className="read-more-btn">
                                <i className="flaticon-right"></i>
                                Learn More

                            </Link>
                            <div className="shape">
                                <img src={shape3} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon1} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                    Full project support
                                </Link>
                            </h3>
                            <ul className="services-list">
                                <li><i className="flaticon-tick"></i> <span>Project management</span></li>
                                <li><i className="flaticon-tick"></i> <span>Budget management</span></li>
                                <li><i className="flaticon-tick"></i> <span>Scoping and discovery</span></li>
                                <li><i className="flaticon-tick"></i> <span>Implementation</span></li>
                                <li><i className="flaticon-tick"></i> <span>Documentation and procedures</span></li>
                            </ul>
                            <Link to="/contact" className="read-more-btn">
                                <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={shape3} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon1} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Incredible Infrastructure
                                </Link>
                            </h3>
                            <ul className="services-list">
                                <li><i className="flaticon-tick"></i> <span>Cloud or on premises</span></li>
                                <li><i className="flaticon-tick"></i> <span>Dev Ops</span></li>
                                <li><i className="flaticon-tick"></i> <span>App store</span></li>
                                <li><i className="flaticon-tick"></i> <span>Play store</span></li>
                                <li><i className="flaticon-tick"></i> <span>Third part services</span></li>
                            </ul>
                            <Link to="/contact" className="read-more-btn">
                                <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={shape3} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon1} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Testing 
                                </Link>
                            </h3>
                            <ul className="services-list">
                                <li><i className="flaticon-tick"></i> <span>Testing existing project</span></li>
                                <li><i className="flaticon-tick"></i> <span>Manual testing</span></li>
                                <li><i className="flaticon-tick"></i> <span>Automaton testing</span></li>
                                <li><i className="flaticon-tick"></i> <span>Smoke testing </span></li>
                                <li><i className="flaticon-tick"></i> <span>Integration testing</span></li>
                            </ul>
                            <Link to="/contact" className="read-more-btn">
                                <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={shape3} alt="services" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="circle-shape2">
                <img src={shape2} alt="banner" />
            </div>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </section>
    )
}

export default OurServices