import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-70 pb-40">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2> 
                    <span className="starts">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
                            <path d="M20 0L24.7148 14.5106H39.9722L27.6287 23.4787L32.3435 37.9894L20 29.0213L7.65651 37.9894L12.3713 23.4787L0.027813 14.5106H15.2852L20 0Z" fill="#6b6b84" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
                            <path d="M20 0L24.7148 14.5106H39.9722L27.6287 23.4787L32.3435 37.9894L20 29.0213L7.65651 37.9894L12.3713 23.4787L0.027813 14.5106H15.2852L20 0Z" fill="#6b6b84" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
                            <path d="M20 0L24.7148 14.5106H39.9722L27.6287 23.4787L32.3435 37.9894L20 29.0213L7.65651 37.9894L12.3713 23.4787L0.027813 14.5106H15.2852L20 0Z" fill="#6b6b84" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
                            <path d="M20 0L24.7148 14.5106H39.9722L27.6287 23.4787L32.3435 37.9894L20 29.0213L7.65651 37.9894L12.3713 23.4787L0.027813 14.5106H15.2852L20 0Z" fill="#6b6b84" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
                            <path d="M20 0L24.7148 14.5106H39.9722L27.6287 23.4787L32.3435 37.9894L20 29.0213L7.65651 37.9894L12.3713 23.4787L0.027813 14.5106H15.2852L20 0Z" fill="#6b6b84" />
                        </svg>
                    </span>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                        <p>I've worked with ITSAS for nearly three years. They built two mobile apps for us, and have provided continuous support and development since. The accuracy and competence of ITSAS have been scrutinised and found to be of a very high standard. ITSAS offers a high level of quality and flexibility, while also being affordable.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <h3>Alex Maxwell</h3>
                                    <span>CEO at ApniSwari</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>I've worked with ITSAS for nearly two years. They built a bespoke eCommerce site for us, and have provided continuous support and development since. The site has performed exceptionally well, with very low bounce rates and conversion rates which regularly beat our industry's best performers. </p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <h3>David Warner</h3>
                                    <span>CEO at LogiTechs</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                    <p>I've worked with ITSAS for nearly three years. They built two mobile apps for us, and have provided continuous support and development since. The accuracy and competence of ITSAS have been scrutinised and found to be of a very high standard. ITSAS offers a high level of quality and flexibility, while also being affordable.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <h3>Alex Maxwell</h3>
                                    <span>CEO at ApniSwari</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>I've worked with ITSAS for nearly two years. They built a bespoke eCommerce site for us, and have provided continuous support and development since. The site has performed exceptionally well, with very low bounce rates and conversion rates which regularly beat our industry's best performers. </p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <h3>David Warner</h3>
                                    <span>CEO at LogiTechs</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </OwlCarousel> : ''}

            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;