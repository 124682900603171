import React from 'react';
import {Link} from 'gatsby'

import starIcon from '../../assets/images/star-icon.png'
import VectorShape11 from '../../assets/images/shape/vector-shape11.png'

const WeServe = () => {
    return (
        <section className="industries-serve-area bg-f1f8fb pt-70 pb-100">
            <div className="container">
                <div className="section-title">
                <span className="sub-title">
                        <img src={starIcon} alt="banner" />
                                People Love Us
                            </span>
                    <h2>Industries We Serve</h2>
                    <p>We’ve been working with SMBs and enterprises from different business domains since 2010 and have in-depth tech expertise to help our partners achieve their goals.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-factory"></i>
                            </div>
                            <span>
                                Manufacturing
                            </span>                            
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-hospital"></i>
                            </div>
                            <span>
                                Healthcare
                            </span>                            
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-tracking"></i>
                            </div>
                            <span>
                                Automobile
                            </span> 
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-investment"></i>
                            </div>
                            <span>
                                Banking
                            </span> 
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-house"></i>
                            </div>
                            <span>
                                Real Estate
                            </span> 
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-order"></i>
                            </div>
                            <span>
                                Logistics
                            </span>                            
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-family-insurance"></i>
                            </div>
                            <span>
                                Insurance
                            </span> 
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-bitcoin"></i>
                            </div>
                            <span>
                                Capital Markets
                            </span> 
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-shopping-basket"></i>
                            </div>
                            <span>
                                ... and Much More
                            </span>
                            <Link to="/contact" className="link-btn"></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape11">
                <img src={VectorShape11} alt="Vector Shape" />
            </div>
        </section>
    )
}

export default WeServe;