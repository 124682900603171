import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

import shape2 from '../../assets/images/shape/circle-shape2.png'


const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area pt-70 pb-100 bg-f1f8fb">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" />
                                People Love Us
                            </span>
                            <h2  className="main-title">Why Choose Us?</h2>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>What we do</h3>
                                    <p>
                                        With a track record in delivering appropriate advice in a meaningful and jargon-free way, our software engineering team can work with you and give you advice on how to develop your products and/or improve your business processes.
                                        By offering you a flexible service, you can benefit from our software engineering expertise in whatever way suits your organisation. We are experienced in working with your in-house team where we can fill any skill gaps that you have, or we are able to take full responsibility for advising and managing your software projects.
                                </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>How we work</h3>
                                    <p>
                                        Your software will be delivered as a unique experience built on strategic planning, collaboration and innovative technology. Custom software development is something of a craft, an intricate process and something we’re passionate about. We have proven success in understanding the complex inter-workings of businesses and creating their perfect solution.
                                        To get started, simply have a chat with one of our friendly and knowledgeable advisers about what you are trying to achieve.
                                </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>What we deliver</h3>
                                    <p>
                                        As well as the custom solutions developed to meet to your specific needs, we also offer a range of market leading in-house developed off the shelf products. These range from complete business management tools through to simple point of sales systems,
                                        Take a look at our product range and be sure to let us know what you think of them.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs