import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import WhyChooseUs from "../components/Index/WhyChooseUs"
import TechnologyStack from "../components/Index/TechnologyStack"
import OurServices from "../components/Index/OurServices"
import Pricing from "../components/Index/Pricing"
import Testimonials from "../components/Index/Testimonials"
import Partner from "../components/Index/Partner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import WeServe from '../components/Index/WeServe'

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <OurSolutions />
      <WhyChooseUs />
      <OurServices /> 
      <WeServe />
      <TechnologyStack/>  
      <Pricing />
      <Testimonials />
      <Partner /> 
      <ProjectStartArea />
      <Footer />
    </Layout>
  )
}

export default Home