import React from 'react'
import ReactWOW from 'react-wow'
import partner1 from '../../assets/images/partner/partner1.png'
import partner2 from '../../assets/images/partner/partner2.png'
import partner3 from '../../assets/images/partner/partner3.png'
import partner4 from '../../assets/images/partner/partner4.png'
import partner5 from '../../assets/images/partner/partner5.png'
import partner6 from '../../assets/images/partner/partner6.png'

const Partner = () => {
    return (
        <section className="pricing-area pt-100 pb-40 bg-f1f8fb">
        <div className="container"> 
            
        <div className="funfacts-area pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={partner1} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={partner2} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={partner3} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={partner4} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={partner5} alt="features" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={partner6} alt="features" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        </div>
    </section>
)
}

export default Partner