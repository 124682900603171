import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import icon2 from '../../assets/images/icons/icon2.png'
import icon3 from '../../assets/images/icons/icon3.png'
import icon5 from '../../assets/images/icons/icon1.png'

const Pricing = () => {
    return (
        <section className="pricing-area pt-70 pb-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                <span className="sub-title">
                        <img src={starIcon} alt="banner" />
                                Lets start
                            </span>
                    <h2>Types of contracts we offer</h2>
                    <p>The diversity of your business objectives and requirements, induce the multiplicity of contract models we suggest.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Time and Material</h3>
                            </div>

                            <div className="price">
                            <img src={icon2} alt="services" />
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Sketchy project concept</li>
                                <li><i className='bx bxs-badge-check'></i> Changeable workflow</li>
                                <li><i className='bx bxs-badge-check'></i> Poorly defined target market</li>
                                <li><i className='bx bxs-badge-check'></i> High control requirements</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Fixed Price</h3>
                            </div>

                            <div className="price">
                            <img src={icon3} alt="services" />
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Defined time frames</li>
                                <li><i className='bx bxs-badge-check'></i> Detailed specification</li>
                                <li><i className='bx bxs-badge-check'></i> Short-term project or MVP</li>
                                <li><i className='bx bxs-badge-check'></i> No changes planned</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Dedicated Team</h3>
                            </div>

                            <div className="price">
                            <img src={icon5} alt="services" />
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Suitable for complex projects</li>
                                <li><i className='bx bxs-badge-check'></i> Long-term collaboration</li>
                                <li><i className='bx bxs-badge-check'></i> Global market targeting</li>
                                <li><i className='bx bxs-badge-check'></i> Desired involvement level</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing