import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon7 from '../../assets/images/services/service-icon7.png'
import serviceIcon8 from '../../assets/images/services/service-icon8.png'
import serviceIcon9 from '../../assets/images/services/service-icon9.png'
import serviceIcon10 from '../../assets/images/services/service-icon10.png'
import serviceIcon11 from '../../assets/images/services/service-icon11.png'
import serviceIcon12 from '../../assets/images/services/service-icon12.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" />
                        We Different From Others
                    </span>
                    <h2>Should Choose Us</h2>
                    <p>With the perfect blend of business understanding, creative thinking, strategy, and user experience (UX) design, together we can achieve great things...</p>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item-wraper">
                                <div className="single-services-box-item">
                                    <div className="icon">
                                        <img src={serviceIcon7} alt="services" />
                                    </div>
                                    <h3>We're Adaptive</h3>
                                    <p></p>

                                    <ul className="services-list">
                                        <li><i className="flaticon-tick"></i> Outsource to us or co create with us</li>
                                        <li><i className="flaticon-tick"></i> Engage in fixed or agile approach</li>
                                        <li><i className="flaticon-tick"></i> Flexibile teams to suit your needs</li>
                                    </ul>

                                    <Link to="/contact" className="learn-more-btn">
                                        <i className="flaticon-right"></i>
                                        Learn More
                                    </Link>
                                    <div className="shape">
                                        <img src={serviceShape4} alt="services" />
                                    </div>
                                </div>
                            </div></div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item-wraper">
                                <div className="single-services-box-item">
                                    <div className="icon">
                                        <img src={serviceIcon8} alt="services" />
                                    </div>
                                    <h3>We're Transparent</h3>
                                    <p></p>

                                    <ul className="services-list">
                                        <li><i className="flaticon-tick"></i> Clear & simple pricing structures</li>
                                        <li><i className="flaticon-tick"></i> Shared industry experience</li>
                                        <li><i className="flaticon-tick"></i> Online real-time project visibility</li>
                                    </ul>
                                    <Link to="/contact" className="learn-more-btn">
                                        <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                                    <div className="shape">
                                        <img src={serviceShape4} alt="services" />
                                    </div>
                                </div>
                            </div></div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item-wraper">
                                <div className="single-services-box-item">
                                    <div className="icon">
                                        <img src={serviceIcon9} alt="services" />
                                    </div>
                                    <h3>We're Cost Effective</h3>
                                    <p></p>

                                    <ul className="services-list">
                                        <li><i className="flaticon-tick"></i> Increase performance & efficiency</li>
                                        <li><i className="flaticon-tick"></i> Reduce costs and risks</li>
                                        <li><i className="flaticon-tick"></i> Scalable, modular, future proof sols</li>
                                    </ul>
                                    <Link to="/contact" className="learn-more-btn">
                                        <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                                    <div className="shape">
                                        <img src={serviceShape4} alt="services" />
                                    </div>
                                </div>
                            </div></div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item-wraper">
                                <div className="single-services-box-item">
                                    <div className="icon">
                                        <img src={serviceIcon10} alt="services" />
                                    </div>
                                    <h3>We're Experienced</h3>
                                    <p></p>

                                    <ul className="services-list">
                                        <li><i className="flaticon-tick"></i> Approved Microsoft certified</li>
                                        <li><i className="flaticon-tick"></i> Goldman Sachs 10K SMB alumnus</li>
                                        <li><i className="flaticon-tick"></i> Projects from 1 month to 2 years</li>
                                    </ul>

                                    <Link to="/contact" className="learn-more-btn">
                                        <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                                    <div className="shape">
                                        <img src={serviceShape4} alt="services" />
                                    </div>
                                </div>
                            </div></div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item-wraper">
                                <div className="single-services-box-item">
                                    <div className="icon">
                                        <img src={serviceIcon11} alt="services" />
                                    </div>
                                    <h3>We're Professional</h3>
                                    <p></p>

                                    <ul className="services-list">
                                        <li><i className="flaticon-tick"></i> Domain expertise</li>
                                        <li><i className="flaticon-tick"></i> High qualified</li>
                                        <li><i className="flaticon-tick"></i> Always up to date</li>
                                    </ul>

                                    <Link to="/contact" className="learn-more-btn">
                                        <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                                    <div className="shape">
                                        <img src={serviceShape4} alt="services" />
                                    </div>
                                </div>
                            </div></div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item-wraper">
                                <div className="single-services-box-item">
                                    <div className="icon">
                                        <img src={serviceIcon12} alt="services" />
                                    </div>
                                    <h3>We're Flexibile</h3>
                                    <p></p>

                                    <ul className="services-list">
                                        <li><i className="flaticon-tick"></i> Quickly responds</li>
                                        <li><i className="flaticon-tick"></i> Elaborate development process</li>
                                        <li><i className="flaticon-tick"></i> No bureaucracy</li>
                                    </ul>

                                    <Link to="/contact" className="learn-more-btn">
                                        <i className="flaticon-right"></i>
                                Learn More
                            </Link>
                                    <div className="shape">
                                        <img src={serviceShape4} alt="services" />
                                    </div>
                                </div>
                            </div></div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;